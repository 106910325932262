.App {
    overflow: hidden
}

.titleText{
    font-size: 64px;
    text-align: center;
}

.center {
    margin: auto;
    width: 50%;
    border: 3px solid green;
    padding: 10px;
}

.feedbackText{
    font-size: 32px;
    text-align: center;
}

.mapTitleText{
    font-size: 32px;
    text-align: center;
}

.csgo-map-image{
    width: 300px;
}

.filter-image{
    width: 25px;
}

.map-page-image{
    align-content: center;
    width: 300px;
}

.flexibleDiv{
    margin: auto;
    width: 80%;
    padding: 10px;
    /* border: 3px solid green; */ /*Useful for debugging*/
}

.textOnImage{
    position: absolute;
    width: 300px;
    top: 35%;
    text-align: center;
    color: white;
    text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000;
}

/* The sticky class is added to the navbar with JS when it reaches its scroll position */
.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
    font-size: 1.5rem;
}

.searchBar {
    margin-bottom: 1.5rem;
    margin-left: 1rem;
    margin-top: 0.5rem;
    width: 85%;
}

.marginTop {
    margin-top: 12px;
}

.sideBar {
    border-radius: 40px;
    border: 2px solid black;
    padding: 20px;
    margin-left: 20px;
    width: 274px;
}

.paddingLeft{
    padding-left: 10px;
}

.clearButton {
    margin-top: 1rem;
    margin-left: 4rem;
}

.grid-container-videos {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 20px;
    grid-column-gap: 45px;
    padding: 10px;
}

.grid-container-smoke-icons {
    display: grid;
    grid-template-columns: 25px 25px 25px 25px 25px;
}

.grid-container-filter-table {
    display: grid;
    grid-template-columns: 30px 110px auto;
    grid-column-gap: 15px;
}

.grid-container-map-page {
    display: grid;
    grid-template-columns: 300px auto;
    grid-gap: 10px;
    padding: 10px;
}

.grid-container-home-page {
    display: grid;
    grid-template-columns: 300px 300px;
    grid-gap: 20px;
    padding: 10px;
}

.grid-container-theme {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 20px;
    padding: 10px;
}

@media only screen and (min-width: 1700px) {
     .grid-container-videos { grid-template-columns: 620px auto }
}

@media only screen and (min-width: 1100px) {
    .grid-container-home-page { grid-template-columns: 300px 300px 300px; }
}

/* Very large devices (large laptops and desktops, 2400px and up) */
@media only screen and (min-width: 2400px) {
    /* Three columns */
    .grid-container-videos { grid-template-columns: 620px 620px auto; }
}